<template>
  <div class="container" id="detail-container">
    <van-loading v-show="loading" class="loading"></van-loading>

    <!-- <div class="top-banner">
      <div class="banner-img">
        <img class="banner" :src="event.cover_url" alt="">
      </div>
    </div> -->

    <div class="main-page">
      <div class="job-detail">
        <div class="top-info">
          <div class="title ellipsis line2">{{ event.title }}</div>
        </div>
        <div class="labels">
        </div>
        <div class="scroll-div" id="scroll-div">
          <van-form @submit="onSubmitStep" @failed="onFailedStep">

            <van-row v-for="(field, i) in fields" :key="i">
              <van-col span="24">

               
                <van-field v-if="field.datatype == 0" :label="field.name" v-model="field.field_value"  
                  placeholder="请填写" :required="field.required" :rules="[{ required: field.required, message: '' }]" />

                <van-field v-if="field.datatype == 1" type="textarea" rows="2" autosize  
                  :label="field.name" v-model="field.field_value" placeholder="请填写" 
                  :required="field.required" :rules="[{ required: field.required, message: '' }]" />

                <van-field v-if="field.datatype == 2"  :label="field.name" :required="field.required" :rules="[{ required: field.required, message: '请选择' }]"  >
                  <template #input>
                    <van-radio-group direction="horizontal" v-model="field.field_value">
                      <van-radio v-for="(op, j) in field.optionsjson" :key="j" :name="op">{{ op }}</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>

                <van-field v-if="field.datatype == 3" :label="field.name" :required="field.required" :rules="[{ required: field.required, message: '请选择' }]" >
                  <template #input>
                    <van-checkbox-group direction="horizontal" v-model="field.field_value">
                      <van-checkbox shape="square" v-for="(op, j) in field.optionsjson" :key="j" :name="op">{{ op
                      }}</van-checkbox>
                    </van-checkbox-group>
                  </template>
                </van-field>

              </van-col>
            </van-row>

            <div class="submit-content">
              
              <van-button class="submit-btn" block type="info" native-type="submit"><span>提交</span></van-button>

            </div>
          </van-form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import cookies from "@/libs/util.cookies";
import { Toast } from "vant";

export default {
  name: "EventDetail",
  props: {
    hasResume: {
      Type: Boolean,
      default: false,
    },
  },
  components: {

  },
  data() {
    return {
      share: false,
      id: "",
      loading: false,
      event: {},
      form: {
        realname: '',
        mobile: '',
        email: '',
        age: '',
        wechat: ''
      },
      fields: []
    };
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 992;
    },
    isLogin() {
      return cookies.get(process.env.VUE_APP_TOKEN);
    },
  },
  watch: {
    "$route.path": {
      handler: function (newPath, oldPath) {
        if (newPath !== oldPath) {

          this.refreshEventDetail();
        }
      },
    },
  },
  created() {
    // 获取活动详情
    this.id = this.$route.query.id;
    this.refreshEventDetail(true);
  },
  mounted() { },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        const container = document.getElementById("detail-container");
        if (container) {
          container.scrollTop = 0;
        }
      });
    },
    // 刷新页面职位信息
    refreshEventDetail(needLoading = false) {
      if (needLoading) this.loading = true;
      this.$api
        .GET_QUESTIONNAIRE_DETAIL({ id: this.id })
        .then((res) => {
          this.loading = false
          this.event = res;
          this.fields = res.psq_field.fields
          this.scrollTop();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 投递
    onApply() {
      var event = this.event

      if (this.isLogin) {
        this.$router.push({
          name: "EventSubmit",
          query: {
            event_id: event.id,
          },
        });

      } else {
        this.$router.push({
          name: "Login",
        });
      }
    },
    onFailedStep() {
      Toast("请完善必填项");
    },
    onSubmitStep() {
      this.onSubmit()
    },
    onSubmit() {

      let answer_data = []

      for( let fieldKey in this.fields){
        answer_data.push({
          'field_id':this.fields[fieldKey].id,
          'datatype':parseInt(this.fields[fieldKey].datatype),
          'field_value':this.fields[fieldKey].field_value || ''
        })
      }

      const param = {
        answer_data:JSON.stringify(answer_data),
        questionnaire_id: this.$route.query.id
      };

      this.loading = true;
      this.$api.GET_QUESTIONNAIRE_SIGN(param).then(
        (res) => {
          this.loading = false;
          console.log(res)
          this.$toast("提交成功！");
        },
        () => {
          this.loading = false;
        }
      );




    }
  },
};
</script>

<style scoped lang="scss">
.container {
  height: calc(100vh - 68px);
  overflow-y: scroll;
  background: white;
}

.top-banner {
  .banner-video {
    width: 100%;
    z-index: 10;
    overflow: hidden;

    .video {
      overflow: hidden;
      // border-radius: 15px 15px 0 0;
    }
  }

  .banner-img img {
    width: 100%;
  }
}

.job-detail {
  padding-top: 18px;
  padding-bottom: 58px;
}

.top-info {
  padding: 0 20px;
  padding-bottom: 12px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 33px;
    margin-right: 20px;
    text-align: left;
  }

  .go-detail {
    width: 60px;
    min-width: 60px;
    margin-right: 30px;
    transform: translateY(1.5px);
    text-decoration: underline;
    color: #e95944;
    font-size: 12px;
    margin-top: 7px;
  }
}

.labels {
  padding: 0 20px;
  padding-bottom: 10px;
  display: flex;
  height: 0px;
  align-items: center;
  border-bottom: 1px solid #f1f4f6;

  .label {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;


    .icon-label {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    span {
      height: 20px;
      line-height: 20px;
    }

    span.work-city {
      max-width: 75px;
    }
  }

  .bar {
    width: 1px;
    height: 15px;
    margin: 0 10px;
    background: #f1f4f6;
  }
}

.scroll-div {
  // padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

  .release-time {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-bottom: 14px;
  }

  .card-info {
    padding: 6px 15px;
    background: #f8fafb;
    border-radius: 10px;
    margin-bottom: 25px;

    .van-col {
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .info-label {
        color: #999999;
      }

      .info-value {
        margin-left: 10px;
        font-weight: bold;
        color: #333333;
        max-width: 70px;
      }
    }
  }

  .sub-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 14px;
  }

  .detail {
    font-size: 15px;
    color: #666666;
    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%;
    line-height: 28px;
    margin-bottom: 25px;

    p {
      margin: 0;
    }
  }

  .company-info {
    padding: 15px;

    .top {
      display: flex;

      .icon {
        margin-right: 12px;

        img {
          // width: 44px;
          height: 44px;
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        font-size: 16px;
        font-weight: bold;
        color: #006647;
      }

      .info {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 12px;
        color: #999999;

        .dot {
          margin: 0px 8px;
          height: 2px;
          width: 2px;
          background: #999999;
        }
      }
    }

    .bottom {
      margin-top: 14px;
      font-size: 15px;
      color: #666666;
      // white-space: pre-wrap;
      text-align: left;
      line-height: 28px;
    }
  }

  .qrcode {
    width: 125px;
    height: 118px;
    align-self: center;

    img {
      width: 125px;
      height: 118px;
    }
  }
}

.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  .collect {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
  }

  .icon-collect {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .btn {
    height: 44px;
    width: 200px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: 1px solid #eaedf0;
    background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
    cursor: pointer;
    // line-height: 44px;
  }

  .confirm {
    color: #ffffff;
  }

  .offline {
    color: rgba(255, 255, 255, 0.3);
  }
}

::v-deep .van-loading {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  overflow: hidden;

  .scroll-div {
    height: 354px;
    max-height: 48vh;
    overflow-y: scroll;
  }
}

@media (min-width: 992px) {

  .container {
    height: calc(100vh - 68px);
    overflow-y: scroll;
    background: white;
  }

  .main-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .job-detail {
    padding-top: 18px;
    padding-bottom: 58px;
  }

  .top-info {
    padding: 0 20px;
    padding-bottom: 12px;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin-right: 20px;
      text-align: left;
    }

    .go-detail {
      width: 60px;
      min-width: 60px;
      margin-right: 30px;
      transform: translateY(1.5px);
      text-decoration: underline;
      color: #e95944;
      font-size: 12px;
      margin-top: 7px;
    }
  }

  .labels {
    padding: 0 20px;
    padding-bottom: 10px;
    display: flex;
    height: 0px;
    align-items: center;

    .label {
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      .icon-label {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }

      span {
        height: 20px;
        line-height: 20px;
      }

      span.work-city {
        max-width: 75px;
      }
    }

    .bar {
      width: 1px;
      height: 15px;
      margin: 0 10px;
      background: #f1f4f6;
    }
  }

  .scroll-div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;

    .release-time {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      margin-bottom: 14px;
    }

    .card-info {
      padding: 6px 15px;
      background: #f8fafb;
      border-radius: 10px;
      margin-bottom: 25px;

      .van-col {
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;

        .info-label {
          color: #999999;
        }

        .info-value {
          margin-left: 10px;
          font-weight: bold;
          color: #333333;
          max-width: 70px;
        }
      }
    }

    .sub-title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 14px;
    }

    .detail {
      font-size: 15px;
      color: #666666;
      word-wrap: break-word;
      word-break: normal;
      white-space: pre-wrap;
      text-align: left;
      max-width: 100%;
      line-height: 28px;
      margin-bottom: 25px;

      p {
        margin: 0;
      }
    }

    .company-info {
      padding: 15px;

      .top {
        display: flex;

        .icon {
          margin-right: 12px;

          img {
            // width: 44px;
            height: 44px;
          }
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          font-size: 16px;
          font-weight: bold;
          color: #006647;
        }

        .info {
          display: flex;
          align-items: center;
          margin-top: 5px;
          font-size: 12px;
          color: #999999;

          .dot {
            margin: 0px 8px;
            height: 2px;
            width: 2px;
            background: #999999;
          }
        }
      }

      .bottom {
        margin-top: 14px;
        font-size: 15px;
        color: #666666;
        // white-space: pre-wrap;
        text-align: left;
        line-height: 28px;
      }
    }

    .qrcode {
      width: 125px;
      height: 118px;
      align-self: center;

      img {
        width: 125px;
        height: 118px;
      }
    }
  }

  .bottom-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    .collect {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
    }

    .icon-collect {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    .btn {
      height: 44px;
      width: 200px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      border-radius: 25px;
      border: 1px solid #eaedf0;
      background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
      // line-height: 44px;
      cursor: pointer;
    }

    .confirm {
      color: #ffffff;
    }

    .offline {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  ::v-deep .van-loading {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .popup {
    overflow: hidden;

    .scroll-div {
      height: 354px;
      max-height: 48vh;
      overflow-y: scroll;
    }
  }

}
</style>

<style scoped  lang="scss">
.resume-page {
  padding: 20px 0;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}

.work-page {
  text-align: left;
  padding-bottom: 65px;
}

.work-page .title {
  padding: 10px 20px;
  margin: 10px 0 0 0;
}

.submit-content {
  margin-top: 20px;
  display: flex;
  padding: 0 10px;
}

.submit-btn {
  flex: 1;
  height: 44px;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border-color: #e95944;
}

.submit-btn.disabled span {
  opacity: 0.5;
}

.submit-btn-cancel span {
  display: block;
  text-align: center;
  line-height: 44px;
}

.field-focus::after {
  border-color: #e95944;
  transform: scaleY(1);
}

.experience-model-card {
  padding: 10px;

  .experience-model-header {

    display: flex;
    align-items: center;

    p {
      font-weight: bold;
      margin-right: 15px;
    }
  }
}

::v-deep {
  .submit-btn.van-button--disabled {
    opacity: 1;
  }

  .submit-btn.van-button--disabled span {
    opacity: 0.5;
  }

  .van-form {
    width: calc(100% - 50px);
    margin: 0 auto;
  }

  .van-cell {
    display: block;
    line-height: 28px;
    padding: 13px 20px;
    font-size: 16px;
    color: #333;
    border-color: #f1f4f6;
  }

  .van-cell__title {
    font-size: 12px;
    color: #666;
    padding-bottom: 5px;
  }

  .field-arrow .van-cell__value {
    position: relative;
    padding-right: 20px;
  }

  .field-arrow .van-cell__value::after {
    display: block;
    content: "";
    width: 19px;
    height: 16px;
    background: url(../../../assets/images/mine/icon-arrow.png);
    background-size: 19px 16px;
    position: absolute;
    right: 0;
    top: 0;
    margin: 8px 0;
  }

  .van-picker__confirm {
    color: #e95944;
  }

  .van-field__label {
    width: 28em;
  }

  .photo {

    .van-uploader__upload,
    .van-uploader__preview-image {
      width: 74px;
      height: 103px;
    }
  }

  .van-step--horizontal {
    text-align: left;
  }

  .van-step__icon--active,
  .van-step__icon--finish,
  .van-step__title--active,
  .van-step__title--finish {
    color: #e95944;
  }

  .van-step--finish .van-step__circle,
  .van-step--finish .van-step__line {
    background: #e95944;
  }

  .experience-table {
    .header {
      font-size: 13px;
      font-weight: bold;
      border: 1px solid #ebedf0;

      .van-col {
        border-right: 1px solid #ebedf0;
        padding: 5px 0;

        &:last-child {
          border: none;
        }
      }

      span {
        display: block;
        color: #e95944;
        font-size: 12px;
      }
    }

    .list {
      font-size: 13px;
      border: 1px solid #ebedf0;
      border-top: none;

      .van-col {
        border-right: 1px solid #ebedf0;

        &:last-child {
          border: none;
        }
      }
    }
  }
}

.form-tips {
  text-align: left;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  ::v-deep {
    .van-col--12 {
      width: 100%;
    }

    .edu-experience-table {

      // .van-col--6,
      // .van-col--4,
      // .van-col--3,
      // .van-col--2 {
      //   width: 100%;
      // }
    }
  }
}

@media (min-width: 992px) {


  .form-tips {
    text-align: left;
    font-weight: bold;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .resume-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .submit-btn {
    max-width: 200px;
    margin: 0 auto;
  }

  .experience-model-card {
    padding: 10px;

    .experience-model-header {

      display: flex;
      align-items: center;

      p {
        font-weight: bold;
        margin-right: 15px;
      }
    }
  }

  ::v-deep {
    .photo {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 148px !important;
        height: 206px !important;
      }
    }

    .van-form {
      width: calc(100% - 0px);
      margin: 0 auto;
    }

    .upload-image {

      .van-uploader__upload,
      .van-uploader__preview-image {
        width: 200px !important;
        min-height: 100px !important;
        height: auto !important;
      }
    }

    .upload-video{
      .van-uploader__preview .van-uploader__file{
        width: 200px !important;
        min-height: 200px !important;
      }
    }

    .uploader-file {

      .van-uploader__file,
      .van-uploader__preview {
        width: 600px !important;
        height: 80px !important;
      }
    }

    .van-field__label {
      width: 40em !important;
    }

    .van-step {
      font-size: 14px;
    }

    .van-step--horizontal .van-step__title {
      font-size: 14px;
    }

    .van-steps--horizontal .van-steps__items {
      margin: 0 0 40px;
      padding-bottom: 25px;
    }

    .van-step--horizontal .van-step__circle-container {
      top: 40px;
    }

    .van-step--horizontal .van-step__line {
      top: 40px;
    }

    .van-step--horizontal .van-step__icon {
      font-size: 32px;
    }

    .van-checkbox__icon {
      font-size: 20px !important;
    }

    .van-checkbox__label {
      margin-left: 8px !important;
      line-height: 20px !important;
    }

    .van-checkbox--horizontal {
      margin-right: 4px !important;
    }


    .experience-table {
      .header {
        font-size: 13px;
        font-weight: bold;
        border: 1px solid #ebedf0;

        .van-col {
          border-right: 1px solid #ebedf0;
          padding: 5px 0;

          &:last-child {
            border: none;
          }
        }

        span {
          display: block;
          color: #e95944;
          font-size: 12px;
        }
      }

      .list {
        font-size: 13px;
        border: 1px solid #ebedf0;
        border-top: none;

        .van-col {
          border-right: 1px solid #ebedf0;

          &:last-child {
            border: none;
          }
        }
      }
    }


  }
}
</style>